import React from "react";
import { Link } from "gatsby";
import { formatPrice } from "../utils";

const ProductCard = ({data}) => {
  return (
    <div className="col-sm-4 product my-2">
      <Link to={"/bg-product-details-" + data.data_id} className="text-decoration-none">
        <div className="card product-item h-100 m-2">
          {data.product_image_link && (<img src={data.product_image_link} alt="product" className="card-img-top product-image"/>)}
          <div className="card-body text-bistre text-center">
            <p className="card-text">{data.product_name}</p>
            <p className="card-text fw-bold" id="price">{formatPrice(data.price)}</p>
            <button className="btn btn-secondary text-white">Купи</button>
          </div>
        </div></Link>
    </div>
  );
};

export default ProductCard;