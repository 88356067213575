import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import ProductsList from "../components/productsList";
import SEO from "../components/seo";

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Начало"/>
      <div className="container pt-3">
        <h2 className="text-secondary text-bold text-center">Топ продукти</h2>
        <ProductsList products={data.allProduct.nodes.filter(product => product.is_product_featured && true)} shouldFilter={false}/>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ProductsQuery {
    allProduct {
      nodes {
        id
        data_id
        brand
        description
        product_name
        sku
        price
        is_product_hidden
        product_image_link
        product_link
        is_product_featured
      }
    }
  }
`;

export default IndexPage;
