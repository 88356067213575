import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import ProductCard from "./product-card";
import translitbg from "translitbg";

const ProductsList = ({ products, shouldFilter=true }) => {
  const [values, setValues] = useState({
    priceLow: "",
    priceHigh: "",
    brand: ""
  });
  const arePricesInRange = (product) => {
    //@TODO: shorten code
    let areInRange = true;
    if(values.priceLow) areInRange = product.price >= values.priceLow;
    if(!areInRange) return false;
    if(values.priceHigh) areInRange = product.price <= values.priceHigh;
    if(!areInRange) return false;
    return areInRange;
  };
  const handleChange = (e) => {
    e.preventDefault();
    const objKey = e.nativeEvent.srcElement.name;
    setValues({...values, [objKey]: parseFloat(e.target.value)});
  };

  const validateBrandSearch = (brand) => {
    const latinizedBrandName = translitbg.go(brand); 
    const latinizedBrandSearchName = translitbg.go(values.brand); 
    return latinizedBrandName.toLowerCase().includes(latinizedBrandSearchName.toLowerCase());
  }
  const handleFilterChange = (e) => {
    e.preventDefault();
    const objKey = e.nativeEvent.srcElement.name;
    setValues({...values, [objKey]: e.target.value});
  };

  const filteredProducts = products.filter(product => (product.is_product_hidden === false && arePricesInRange(product) && validateBrandSearch(product.brand)));
  return (
    <>{shouldFilter && (<div>
      <div className="input-group mb-3">
        <span className="input-group-text">Цена</span>
        <span className="input-group-text" id="basic-addon1">От</span>
        <input name="priceLow" defaultValue="0.00" type="number" className="form-control" placeholder="0.00" aria-label="Pricelow" aria-describedby="basic-addon1" onChange={handleChange} />
        <span className="input-group-text" id="basic-addon2">До</span>
        <input type="number" defaultValue="200.00" name="priceHigh" className="form-control" placeholder="200.00" aria-label="Pricehigh" aria-describedby="basic-addon2" onChange={handleChange} />
      </div>
      <div className="input-group">
        <span className="input-group-text">Марка</span>
        <input name="brand" defaultValue="" type="text" className="form-control"  aria-label="filter by brand" aria-describedby="basic-addon3" onChange={handleFilterChange} />
      </div>
    </div>)}<div className="row">
      {(products.length === 0 || filteredProducts.length === 0) && (<p>Няма намерени продукти.</p>)}
      {filteredProducts.map(product => product.id && (<ProductCard data={product} key={`content_item_${product.id}`} />))}
    </div><br /></>
  );
};

export default ProductsList;